
















































































































import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KTableActions from '@/@core/components/table/KTableActions.vue';
import KTableActionButton from '@/@core/components/table/KTableActionButton.vue';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';
import KTableWrapper from '@/@core/components/table/KTableWrapper.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import {
  defineComponent,
  onMounted,
  Ref,
  ref
} from '@vue/composition-api';
import { useMarketingAreaModel } from './model';
import KDialog from '@/@core/components/dialogs/KDialog.vue';
// import MarketingAreaForm from '../../ui/Marketing-Area-form.vue';
// @ts-ignore
import MarketingAreaForm from '../../ui/marketing-area-form.vue';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KText,
    KPageBody,
    KWrapper,
    KCard,
    KHamburgerMenu,
    KTableWrapper,
    KTextInput,
    KTableSearch,
    KTableActionButton,
    KTableActions,
    KDialog,
    MarketingAreaForm
  },
  name: 'MarketingAreaDefaultPage',
  setup() {
    onMounted(async () => {
      await useMarketingAreaModel().onGetManyMarketingArea();
    });

    return {
      isMobile,
      ...useMarketingAreaModel()
    };
  }
});
