import { useUiStateStore } from '@/@core/services/uiStateStore';
import { reactive, toRefs } from '@vue/composition-api';
import { useMarketingAreaStore } from '../../services/store';

type MarketingAreaModelState = {
  headers: any[];
  isLoading: boolean;
  showFormDialog: boolean;
  showDeleteDialog: boolean;
  isFormValid: boolean;
  formData: any;
  search: string;
};

const state = reactive<MarketingAreaModelState>({
  headers: [
    {
      text: 'Nama Wilayah Pemasaran',
      value: 'name'
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      width: '1%'
    }
  ],
  isLoading: false,
  showFormDialog: false,
  showDeleteDialog: false,
  isFormValid: false,
  formData: null,
  search: ''
});

export default function useMarketingAreaModel() {
  const store = useMarketingAreaStore();

  const onGetManyMarketingArea = async () => {
    state.isLoading = true;

    try {
      state.isLoading = true;
      await store.getManyMarketingArea();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onCreateMarketingArea = async (data: any) => {
    state.isLoading = true;

    try {
      await store.postMarketingArea(data);
      useUiStateStore().createSnackbar({
        message: `Wilayah Pemasaran ${data.name} berhasil ditambahkan.`
      });

      state.showFormDialog = false;
      await onGetManyMarketingArea();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onUpdateMarketingArea = async (data: any) => {
    state.isLoading = true;

    try {
      await store.putMarketingArea(data);
      useUiStateStore().createSnackbar({
        message: `Wilayah Pemasaran ${data.name} berhasil diubah.`
      });
      state.showFormDialog = false;
      await onGetManyMarketingArea();
      state.formData = null;
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const createMarketingArea = () => {
    state.formData = null;
    state.showFormDialog = true;
  };

  const editMarketingArea = (marketingarea: any) => {
    state.formData = { ...marketingarea };
    state.showFormDialog = true;
  };

  const deleteMarketingArea = (marketingarea: any) => {
    state.formData = marketingarea;
    state.showDeleteDialog = true;
  };

  const onDeleteMarketingArea = async () => {
    state.isLoading = true;

    try {
      await store.deleteMarketingArea(state.formData);
      const data: any = state.formData || {};
      useUiStateStore().createSnackbar({
        message: `Wilayah Pemasaran ${data.name} berhasil dihapus.`
      });
      state.showDeleteDialog = false;
      state.formData = null;
      await onGetManyMarketingArea();
    } catch (err: any) {
      // useErrorMessageHandler(err)
    } finally {
      state.isLoading = false;
    }
  };

  const onSubmitForm = () => {
    const isEdit = (state.formData as any)?.id;

    if (isEdit) onUpdateMarketingArea(state.formData);
    else onCreateMarketingArea(state.formData);
  };

  return {
    ...toRefs(state),
    store,
    onGetManyMarketingArea,
    onSubmitForm,
    onDeleteMarketingArea,
    deleteMarketingArea,
    editMarketingArea,
    createMarketingArea
  };
}

export { useMarketingAreaModel };
